@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  display: flex;
  flex-direction: column;
  @apply bg-gray-800 text-white;

}

body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}